



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IconsType, Document } from "@/types";
import { mdiPaperclip, mdiUploadOutline } from "@mdi/js";
const ItemModule = namespace("ItemModule");
const AppModule = namespace("AppModule");
const size = 16 * 1024 * 1024;
const sizeText = "16 MiB";
const docCount = 20;
@Component({
  components: {
    LabelDocument: () => import("@/components/Label/LabelDocument.vue")
  }
})
export default class StepperDocumentsUpload extends Vue {
  @Prop() readonly description!: string;
  @Prop() readonly needAdditionalDocuments!: boolean;
  @ItemModule.State
  documents!: Document[];
  @ItemModule.Getter
  documentsSize!: number;
  @AppModule.Getter
  public smallScreen!: string;
  private truncateLength = 15;
  private uploading = false;
  private fileInputId = "file-input-documents";
  private files: File[] = [];
  private accept = "image/png,image/jpeg,application/pdf,image/pjpeg";
  private valid = false;
  private icons: IconsType = {
    upload: mdiUploadOutline,
    file: mdiPaperclip
  };
  private rules = [
    () =>
      this.ruleMaxLength() ||
      this.$t("pages.item.stepper.rules.number", { n: docCount }),
    () =>
      this.ruleMaxSize() ||
      this.$t("pages.item.stepper.rules.size", { s: sizeText }),
    (t: File[]) =>
      t.every(({ type }) => this.accept.split(",").includes(type)) ||
      this.$t("pages.item.stepper.rules.type-docs")
  ];
  public ruleMaxLength(): boolean {
    return this.files.length + this.documentsLength <= docCount;
  }
  public ruleMaxSize(): boolean {
    return (
      this.documentsSize +
        this.files.reduce((sum, { size }) => sum + size, 0) <=
      size
    );
  }
  get documentsLength() {
    return this.documents ? this.documents.length : 0;
  }
  removeFile(index: number) {
    this.files.splice(index, 1);
  }
  async removeDocument(index: number) {
    await this.$store.dispatch(
      "ItemModule/deleteDocument",
      this.documents[index].id
    );
    this.$store.commit("ItemModule/DELETE_DOCUMENT", index);
  }
  uploadFiles() {
    this.uploading = true;
    setTimeout(async () => {
      if (this.valid) {
        const res = await this.$store.dispatch(
          "ItemModule/uploadDocuments",
          this.files
        );
        if (!res) this.$emit("error");
        else this.files = [];
      }
      this.uploading = false;
    }, 0);
  }
  public onPrependInnerClick() {
    document.getElementById(this.fileInputId)?.click();
  }
}
